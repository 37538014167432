import React from "react";
import EventSec from "../sections/EventSec";
import FloatingImage from '../sections/FloatingImage';

const EventPage = ()=>{
    return(
        <EventSec/>
        
    )
}

export default EventPage;