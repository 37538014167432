import React from "react";
import TechTeamSec from "../sections/TechTeamSec";
// import SpheresBackground from './SpheresBackground';
const TechTeam = ()=>{
    return(
        <>
        <TechTeamSec/>
        {/* <SpheresBackground/> */}
        </>
    )
}

export default TechTeam;